import React, { useReducer, useContext } from "react";
import axios from "axios";
import { get } from "lodash";
import CustomerContext from "./customerContext";
import CustomerReducer from "./CustomerReducer";
import {
    SET_LOADING,
    SET_CATEGORY_OPTIONS_LOADING,
    GET_CUSTOMER,
    GET_CUSTOMER_COUNTS,
    GET_ALL_CUSTOMERS,
    GET_NOT_ACTIVATED_CUSTOMERS,
    GET_ACTIVATED_CUSTOMERS,
    GET_CUSTOMER_PAGE,
    GET_CUSTOMER_DEALERS,
    GET_DEALER_CUSTOMERS,
    GET_CATEGORY_OPTIONS,
    GET_MARKETING_CAMPAIGNS_CUSTOMER,
    GET_MARKETING_CAMPAIGNS_DEALER,
    GET_COMMUNICATIONS_CUSTOMER,
    SET_CUSTOMER_FILTER_VALUE,
    SET_CUSTOMER_DEALERS_PAGE,
    SET_DEALER_CUSTOMERS_PAGE,
    SET_CUSTOMER_PAGE
} from "../types";
import SearchContext from "../search/searchContext";
import { checkCustomerFilter } from "../../tables/customerFilterOptions";
import { replaceHyphens } from "../../utils/utils";
import { setLoading, setLoaded } from "../../utils/state";

const CustomerState = props => {
    const searchContext = useContext(SearchContext);
    const { startDateMySQL, endDateMySQL, paginationValue } = searchContext;

    const initialState = {
        customer: {},
        customers: [],
        customerDealers: [],
        customerLinks: {},
        customerTotal: "",
        customerCounts: [],
        customerCount: 0,
        customerDealerCount: 0,
        customerNotActivatedLinks: {},
        customerNotActivatedTotal: "",
        customerActivatedLinks: {},
        customerActivatedTotal: "",
        customersActivated: [],
        customersActivatedTotal: 0,
        customersNotActivated: [],
        marketingCampaignsCustomer: [],
        marketingCampaignsDealer: [],
        categoryOptions: [],
        categoryOptionsLoaded: false,
        loading: false,
        itemsLoading: [],
        customerFilterValue: "all-perks-dates",
        customerDealersPage: 1,
        dealerCustomersPage: 1,
        customerPage: 1
    };

    const [state, dispatch] = useReducer(CustomerReducer, initialState);
    const setLoadingCustomerState = setLoading(state.itemsLoading, dispatch);
    const setLoadedCustomerState = setLoaded(state.itemsLoading, dispatch);

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setCustomerPage = pageNumber => {
        dispatch({
            type: SET_CUSTOMER_PAGE,
            payload: pageNumber
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setCustomerDealersPage = pageNumber => {
        dispatch({
            type: SET_CUSTOMER_DEALERS_PAGE,
            payload: pageNumber
        });
    };

    /**
     * @param {integer} pageNumner
     * @return {undefined}
     */
    const setDealerCustomersPage = pageNumber => {
        dispatch({
            type: SET_DEALER_CUSTOMERS_PAGE,
            payload: pageNumber
        });
    };

    /**
     * @param {string} filter
     * @return {undefined}
     */
    const setCustomerFilterValue = filter => {
        dispatch({
            type: SET_CUSTOMER_FILTER_VALUE,
            payload: filter
        });
    };

    /**
     * Ensure the filter value passed is a customerFilter
     * if not, set default filter instead
     *
     * @param {string} rawFilter
     * @return {undefined}
     */
    const setCustomerFilterOrDefault = rawFilter => {
        const filter = checkCustomerFilter(replaceHyphens(rawFilter));
        setCustomerFilterValue(filter);
    };

    const getCustomerCounts = async () => {
        setLoadingCustomerState(GET_CUSTOMER_COUNTS);

        const res = await axios.get(
            `/api/v1/customer/counts/${startDateMySQL}/${endDateMySQL}`
        );

        dispatch({
            type: GET_CUSTOMER_COUNTS,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_CUSTOMER_COUNTS);
    };

    const getAllCustomers = async () => {
        setLoadingCustomerState(GET_ALL_CUSTOMERS);

        const res = await axios.get(`/api/v1/customers`);

        dispatch({
            type: GET_ALL_CUSTOMERS,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_ALL_CUSTOMERS);
    };
    const getActivatedCustomers = async ({
        customerFilterValue: customerFilterValueRaw,
        start,
        end
    }) => {
        const filter = replaceHyphens(customerFilterValueRaw);
        setLoadingCustomerState(GET_ACTIVATED_CUSTOMERS);

        const res = await axios.get(
            `/api/v1/customers/${filter}/${start}/${end}?pagination=${paginationValue}&page=${state.customerPage}`
        );

        dispatch({
            type: GET_ACTIVATED_CUSTOMERS,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_ACTIVATED_CUSTOMERS);
    };
    const getNotActivatedCustomers = async () => {
        setLoadingCustomerState(GET_NOT_ACTIVATED_CUSTOMERS);

        const res = await axios.get(`/api/v1/customers-not-activated`);

        dispatch({
            type: GET_NOT_ACTIVATED_CUSTOMERS,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_NOT_ACTIVATED_CUSTOMERS);
    };

    const getCustomerDealers = async ({
        customerFilterValue: customerFilterValueRaw,
        start,
        end
    }) => {
        const filter = replaceHyphens(customerFilterValueRaw);
        setLoadingCustomerState(GET_CUSTOMER_DEALERS);

        const res = await axios.get(
            `/api/v1/customer-dealers/${filter}/${start}/${end}?pagination=${paginationValue}&page=${state.customerDealersPage}`
        );

        dispatch({
            type: GET_CUSTOMER_DEALERS,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_CUSTOMER_DEALERS);
    };

    const getDealerCustomers = async ({
        customerFilterValue: customerFilterValueRaw,
        id
    }) => {
        const filter = replaceHyphens(customerFilterValueRaw);
        setLoadingCustomerState(GET_DEALER_CUSTOMERS);

        const res = await axios.get(
            `/api/v1/customer/dealer/${id}/${filter}/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.customerDealersPage}`
        );

        dispatch({
            type: GET_DEALER_CUSTOMERS,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_DEALER_CUSTOMERS);
    };

    const getCustomerPage = async pageNumber => {
        setLoadingCustomerState(GET_CUSTOMER_PAGE);

        const res = await axios.get(
            `/api/v1/customers/${startDateMySQL}/${endDateMySQL}?page=${pageNumber}`
        );

        dispatch({
            type: GET_CUSTOMER_PAGE,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_CUSTOMER_PAGE);
    };

    const getCustomer = async id => {
        setLoadingCustomerState(GET_CUSTOMER);

        const res = await axios.get(`/api/v1/customer/${id}`);

        dispatch({
            type: GET_CUSTOMER,
            payload: get(res, "data.data", {})
        });

        setLoadedCustomerState(GET_CUSTOMER);
    };

    const getCategoryOptions = async () => {
        dispatch({
            type: SET_CATEGORY_OPTIONS_LOADING,
            payload: true
        });

        const res = await axios.get(`/api/v1/category-options`);

        dispatch({
            type: GET_CATEGORY_OPTIONS,
            payload: get(res, "data", {})
        });
    };

    const getMarketingCampaignsCustomer = async id => {
        setLoadingCustomerState(GET_MARKETING_CAMPAIGNS_CUSTOMER);

        const res = await axios.get(
            `/api/v1/customer/marketing-campaigns/${id}`
        );

        dispatch({
            type: GET_MARKETING_CAMPAIGNS_CUSTOMER,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_MARKETING_CAMPAIGNS_CUSTOMER);
    };

    const getMarketingCampaignsDealer = async () => {
        setLoadingCustomerState(GET_MARKETING_CAMPAIGNS_DEALER);

        const res = await axios.get(`/api/v1/dealer-marketing-campaigns`);

        dispatch({
            type: GET_MARKETING_CAMPAIGNS_DEALER,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_MARKETING_CAMPAIGNS_DEALER);
    };

    const getCommunicationsCustomer = async id => {
        setLoadingCustomerState(GET_COMMUNICATIONS_CUSTOMER);

        const res = await axios.get(`/api/v1/customer/communications/${id}`);

        dispatch({
            type: GET_COMMUNICATIONS_CUSTOMER,
            payload: get(res, "data", {})
        });

        setLoadedCustomerState(GET_COMMUNICATIONS_CUSTOMER);
    };

    return (
        <CustomerContext.Provider
            value={{
                customers: state.customers,
                customer: state.customer,
                customerDealers: state.customerDealers,
                customerDealersPage: state.customerDealersPage,
                dealerCustomersPage: state.dealerCustomersPage,
                customerPage: state.customerPage,
                customerTotalCount: state.customerTotalCount,
                customerTotal: state.customerTotal,
                customerCounts: state.customerCounts,
                customerCount: state.customerCount,
                customerLinks: state.customerLinks,
                customerDealerCount: state.customerDealerCount,
                customersActivated: state.customersActivated,
                customerActivatedTotal: state.customerActivatedTotal,
                customerActivatedLinks: state.customerActivatedLinks,
                customersNotActivated: state.customersNotActivated,
                customersActivatedTotal: state.customersActivatedTotal,
                customerNotActivatedTotal: state.customerNotActivatedTotal,
                customerNotActivatedLinks: state.customerNotActivatedLinks,
                categoryOptions: state.categoryOptions,
                marketingCampaignsCustomer: state.marketingCampaignsCustomer,
                marketingCampaignsDealer: state.marketingCampaignsDealer,
                customerCommunications: state.customerCommunications,
                loading: state.loading,
                categoryOptionsLoaded: state.categoryOptionsLoaded,
                customerFilterValue: state.customerFilterValue,
                setCustomerDealersPage,
                setCustomerPage,
                setDealerCustomersPage,
                setCustomerFilterValue,
                setCustomerFilterOrDefault,
                setLoading,
                getCustomerPage,
                getCustomerDealers,
                getDealerCustomers,
                getCustomerCounts,
                getAllCustomers,
                getActivatedCustomers,
                getNotActivatedCustomers,
                getCustomer,
                getCategoryOptions,
                getMarketingCampaignsCustomer,
                getMarketingCampaignsDealer,
                getCommunicationsCustomer
            }}
        >
            {props.children}
        </CustomerContext.Provider>
    );
};

export default CustomerState;
