import React, { Fragment } from "react";
import Ifbr from "./Ifbr";
import logo from "../../images/distrigo_logo.svg";
import moment from "moment";

const Header = ({ customer }) => {
    const {
        postcode,
        phone,
        email_to_use,
        address_1,
        address_2,
        address_3,
        address_4,
        address_5,
        contact_first_name,
        contact_last_name,
        contact_mobile_phone,
        trade_team_id,
    } = customer;

    const address = (
        <span className="text-right">
            <Ifbr element={address_1} />
            <Ifbr element={address_2} />
            <Ifbr element={address_3} />
            <Ifbr element={address_4} />
            <Ifbr element={address_5} />
            <Ifbr element={postcode} />
            <br />
        </span>
    );
    return (
        <Fragment>
            <div className="row column communication__header">
                <div className="columns small-4 communication__distrigo">
                    <img src={logo} alt="Distrigo" />
                    <div className="communication__detail">
                        <div className="details">
                            <p>Date:</p>
                            <span>
                                {moment(new Date()).format("DD/MM/YYYY")}
                            </span>
                        </div>
                        <div className="details">
                            <p>Distrigo Distribution Number:</p>
                            <span>{trade_team_id}</span>
                        </div>
                        <div className="details">
                            <p>DISTRIGO PERKS Activated:</p>
                            <span>
                                {customer.calc.activated_at
                                    ? moment(customer.calc.activated_at).format(
                                          "DD/MM/YYYY"
                                      )
                                    : "-"}
                            </span>
                        </div>
                        <div className="details">
                            <p>DISTRIGO PERKS Status:</p>
                            <span>
                                {customer.calc.perks_status}
                            </span>
                        </div>
                        <div className="details">
                            <p>Permissioned:</p>{" "}
                            <span>
                                {customer.calc.tier_2_activated_user_permission
                                    ? "Yes"
                                    : "No"}
                            </span>
                        </div>
                        <div className="details">
                            <p>Verified:</p>{" "}
                            <span>
                                {customer.calc
                                    .tier_2_activated_user_permission_verified
                                    ? "Yes"
                                    : "No"}
                            </span>
                        </div>
                        <div className="details">
                            <p>Primary Dealer:</p>
                            <span>{customer.dealer}</span>
                        </div>
                    </div>
                </div>
                <div className="columns small-4 communication__customer">
                    <div className="communication__detail">
                        <div className="details">
                            <p>Customer Name:</p>
                            <span>{customer.trade_team_customer}</span>
                        </div>
                        <div className="details">
                            <p>Customer Type:</p>
                            <span>{customer.calc.type}</span>
                        </div>
                        {contact_first_name && contact_last_name && (
                            <div className="details">
                                <p>Contact Name:</p>
                                <span>{`${contact_first_name} ${contact_last_name}`}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="columns small-4 communication__address">
                    <div className="communication__detail">
                        <div className="details">
                            <p>Address:</p>
                            {address}
                        </div>
                        <div className="details">
                            <p>Phone:</p>
                            <span>{phone}</span>
                        </div>
                        <div className="details">
                            <p>Email:</p> <span>{email_to_use}</span>
                        </div>
                        {contact_mobile_phone && (
                            <div className="details">
                                <p>Mobile:</p> <span>{contact_mobile_phone}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;
