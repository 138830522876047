import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString } from "lodash";
import {
    checkInteger,
    checkString,
    getFilterByValue,
    prepareDateString,
} from "../../utils/utils";
import TableLayout from "../../containers/TableLayout";
import Spinner from "../../containers/Spinner";
import { useIsFirstRender } from "../../utils/isFirstRender";
import CustomerRow from "./CustomerRow";
import CustomerContext from "../../context/customer/customerContext";
import UserContext from "../../context/user/userContext";
import SearchContext from "../../context/search/searchContext";
import { getCustomerFilterOptions } from "../customerFilterOptions";
import GetOnFirstRender from "../GetOnFirstRender";

const CustomersTable = ({ match }) => {
    const {
        params: { filter, from, to },
    } = match;

    const {
        customersActivated,
        getActivatedCustomers,
        categoryOptions,
        getCategoryOptions,
        categoryOptionsLoaded,
        loading,
        customerFilterValue,
        setCustomerFilterValue,
        setCustomerFilterOrDefault,
        customerPage,
        setCustomerPage,
        customersActivatedTotal,
    } = useContext(CustomerContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);
    const { groupType, user } = useContext(UserContext);

    const [value, setValue] = useState('all');
    const isFirstRender = useIsFirstRender();
    const customerFilterOptions = getCustomerFilterOptions(groupType);
    const filterObject = getFilterByValue(
        customerFilterOptions,
        customerFilterValue
    );
    const { dates } = filterObject;

    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);

    const handleSelectChange = (event) => {
        setValue(event.target.value);
    };

    const getItemsUrl = () => {
        getCategoryOptions();
        getActivatedCustomers({
            customerFilterValue: isString(filter)
                ? filter
                : customerFilterValue,
            start: prepareDateString(from, startDateMySQL),
            end: prepareDateString(to, endDateMySQL),
        });
    };

    const getItemsState = () =>
        getActivatedCustomers({
            customerFilterValue: customerFilterValue,
            start: startDateMySQL,
            end: endDateMySQL,
        });

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">Customers</h3>
                <GetOnFirstRender
                    to={to}
                    from={from}
                    filter={filter}
                    setFilterValue={setCustomerFilterValue}
                    getItems={getItemsUrl}
                />
                <TableLayout
                    activePage={customerPage}
                    setActivePage={setCustomerPage}
                    totalItems={checkInteger(customersActivatedTotal)}
                    getItems={getItemsState}
                    setFilterValue={setCustomerFilterValue}
                    filterValue={customerFilterValue}
                    filterOptions={customerFilterOptions}
                    showDates={dates}
                    exportLink={`${apiUrl}/download/Customers/${user.uuid}/${groupType}/${startDateMySQL}/${endDateMySQL}/${customerFilterValue}`}
                >
                    <table
                        id="customers-table"
                        className="responsive"
                        width="100%"
                    >
                        <tbody>
                            <tr>
                                <th>Customer</th>
                                <th>ID</th>
                                <th>GDPR</th>
                                <th>Type</th>
                                <th>Points Awarded</th>
                                <th>
                                    <select
                                        value={value}
                                        onChange={handleSelectChange}
                                    >
                                        <option disabled selected value> -- Select An Option -- </option>
                                        {!categoryOptionsLoaded &&
                                            map(categoryOptions, (option) => {
                                                return (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </th>
                                <th>Points Claimed</th>
                                <th>Points Expired</th>
                                <th>Points Available</th>
                                <th>Permissions</th>
                                <th>DISTRIGO PERKS</th>
                                <th>View</th>
                            </tr>

                            {!loading &&
                                map(customersActivated, (customer, i) => (
                                    <CustomerRow
                                        key={i}
                                        customer={customer}
                                        selectOption={value}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading || categoryOptionsLoaded ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
    //}
};

export default CustomersTable;
