import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString } from "lodash";
import { checkArray, checkInteger, getFilterByValue } from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import TableLayout from "../../containers/TableLayout";
import Spinner from "../../containers/Spinner";
import CustomerRow from "../CustomersTable/CustomerRow";
import CustomerContext from "../../context/customer/customerContext";
import DealerContext from "../../context/dealer/dealerContext";
import SearchContext from "../../context/search/searchContext";
import { customerFilterOptions } from "../customerFilterOptions";
import GetOnFirstRender from "../GetOnFirstRender";

const DealerCustomersTable = ({ match }) => {
    const {
        params: { dealer_id: dealerId, filter, from, to },
    } = match;

    const {
        customers,
        getDealerCustomers,
        getCustomerPage,
        categoryOptions,
        getCategoryOptions,
        loading,
        setCustomerDealersPage,
        customerDealersPage,
        customerFilterValue,
        setCustomerFilterValue,
        setCustomerFilterOrDefault,
        customerCount,
    } = useContext(CustomerContext);

    const [value, setValue] = useState('all');
    const filterObject = getFilterByValue(
        customerFilterOptions,
        customerFilterValue
    );
    const { dates } = filterObject;
    const { dealer, getDCDealer, dealerLoading } = useContext(DealerContext);
    const title =
        dealer && dealer.name ? `Customers > ${dealer.name}` : "Customers";

    const handleSelectChange = (event) => {
        setValue(event.target.value);
    };

    const getItemsUrl = () => {
        getCategoryOptions();
        getDCDealer(dealerId);
        getDealerCustomers({
            customerFilterValue: isString(filter)
                ? filter
                : customerFilterValue,
            id: dealerId,
        });
    };

    const getItemsState = () => {
        getDealerCustomers({
            customerFilterValue: customerFilterValue,
            id: dealerId,
        });
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">{title}</h3>
                <GetOnFirstRender
                    from={from}
                    to={to}
                    filter={filter}
                    setFilterValue={setCustomerFilterValue}
                    getItems={getItemsUrl}
                />
                <TableLayout
                    activePage={customerDealersPage}
                    setActivePage={setCustomerDealersPage}
                    getItems={getItemsState}
                    totalItems={checkInteger(customerCount)}
                    filterOptions={customerFilterOptions}
                    filterValue={customerFilterValue}
                    setFilterValue={setCustomerFilterValue}
                    showDates={dates}
                >
                    <table
                        id="dealer-customers"
                        className="responsive"
                        width="100%"
                    >
                        <tbody>
                            <tr>
                                <th>Customer</th>
                                <th>ID</th>
                                <th>GDPR</th>
                                <th>Type</th>
                                <th>Points Awarded</th>
                                <th>
                                    <select
                                        value={value}
                                        onChange={handleSelectChange}
                                    >
                                        <option disabled selected value> -- Select An Option -- </option>
                                        {map(categoryOptions, (option) => {
                                            return (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </th>
                                <th>Points Claimed</th>
                                <th>Points Expired</th>
                                <th>Points Available</th>
                                <th>Permissions</th>
                                <th>DISTRIGO PERKS</th>
                                <th>View</th>
                            </tr>

                            {!loading &&
                                map(customers, (customer, i) => (
                                    <CustomerRow
                                        key={customer.trade_team_id}
                                        customer={customer}
                                        selectOption={value}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading || dealerLoading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default DealerCustomersTable;
