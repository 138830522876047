import React, { Fragment, useContext, useState, useEffect } from "react";
import { __RouterContext } from "react-router-dom";
import FromToPicker from "../FromToPicker";
import FilterSelect from "./FilterSelect";
import SearchBar from "./SearchBar";
import SearchContext from "../../context/search/searchContext";
import {
    checkFunction,
    runFunction,
    checkString,
    updateUrlMaybe,
    getFilterByValue,
} from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import { map } from "lodash";

const TableHeader = ({
    title,
    getItems,
    filterOptions,
    setFilterValue,
    filterValue,
    showDates,
    summaryPage,
    useFilterOptions,
    hideFilter,
    showSearchBox
}) => {
    const {
        startDate,
        endDate,
        searchText,
        setEndDate,
        setStartDate,
        setSearchText,
        formSubmit,
        toggleFormSubmit,
    } = useContext(SearchContext);

    const {
        match: { path, url },
        history,
    } = useContext(__RouterContext);

    // for FromToPicker
    const [start, setStart] = useState(startDate);
    const [end, setEnd] = useState(endDate);

    const chosenStartDate = (date) => setStart(date);
    const chosenEndDate = (date) => setEnd(date);

    const [text, setText] = useState(searchText);

    // filters
    const filterObject =
        useFilterOptions !== false
            ? getFilterByValue(filterOptions, filterValue)
            : "";
    const [filter, setFilter] = useState(
        useFilterOptions !== false ? filterValue : ""
    );

    const isFirstRender = useIsFirstRender();

    const handleSubmit = () => {
        setEndDate(end);
        setStartDate(start);
        setSearchText(text);

        if (useFilterOptions !== false) setFilterValue(filter);
        toggleFormSubmit(formSubmit);
    };

    useEffect(() => {
        if (!isFirstRender === true) runFunction(getItems);
    }, [formSubmit]);

    useEffect(() => {
        setFilter(filterValue);

        chosenStartDate(startDate);
        chosenEndDate(endDate);

        if (useFilterOptions !== false && isFirstRender === false)
            updateUrlMaybe(
                history,
                path,
                url,
                filterObject,
                startDate,
                endDate
            );
    }, [filterValue, startDate, endDate]);

    return (
        <Fragment>
            <div className="row">
                <div className="large-12 columns">
                    <h5>{title}</h5>
                </div>
            </div>
            {hideFilter !== true && (
                <div className="row table__header">
                    <div className="columns small-4">

                        {useFilterOptions !== false && (
                            <FilterSelect
                                filter={filter}
                                filterValue={filterValue}
                                filterOptions={filterOptions}
                                setFilterValue={setFilterValue}
                            />
                        )}

                        <SearchBar text={text} onChange={setText} showSearchBox={showSearchBox} filter={filter} />
                    </div>

                    <div
                        className="large-8 columns text-right"
                        style={{ float: "right" }}
                    >
                        {showDates !== false && (
                            <FromToPicker
                                start={start}
                                end={end}
                                setStart={chosenStartDate}
                                setEnd={chosenEndDate}
                            />
                        )}
                        <button
                            onClick={handleSubmit}
                            className="button"
                            style={{ marginBottom: "4px" }}
                        >
                            Filter
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default TableHeader;
